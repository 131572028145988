.customTooltip {
    background: var(--elevation3);
    border: 1px solid var(--gray7);
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.09);
    border-radius: 0.5rem;
    padding-bottom: 0.25rem;
    box-shadow: var(--shadow-lg);
}

.customTooltipHeaderContainer {
    padding: 0.5rem 0.25rem 0.5rem 1rem;
    border-bottom: 1px solid var(--gray6);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    height: 2.5rem;
}

.customTooltipRowsContainer {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
}

.customTooltipRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    gap: 2rem;
    height: 2rem;
}

.customTooltipHeading {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--gray12);
}

.customTooltipRowLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.customTooltipPeriodLabel {
    color: var(--gray11);
    font-size: 0.75rem;
}

.customTooltipStraightLine {
    display: inline-block;
    width: 24px;
    height: 3px;
    border-radius: 1rem;
}
.customTooltipDashedLineContainer {
    display: flex;
    flex-direction: row;
    width: 24px;
    height: 100%;
    justify-content: space-between;
    gap: 2px;
}

.customTooltipDashedLine {
    display: block;
    flex-grow: 1;
    border-radius: 1rem;
    height: 3px;
}
.barChartNavigation {
  width: 100%;
}

.pageNumberContainer {
  font-size: 0.875rem;
  color: var(--gray11);
  display: flex;
  gap: 0.5rem;
  align-items: center;
  white-space: nowrap;
  padding: 1rem;
  z-index: 99;
}

.pageNumberContainer > svg:hover {
  cursor: pointer;
  color: var(--gray12);
}

.wrapper {
  display: flex;
  width: 100%;
  backdrop-filter: blur(4px);
  background: #ffffff01;
  height: 400px;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.content {
  padding: 2px;
  overflow: hidden;
  height: 3.5rem;
  border-radius: 99rem;
  isolation: isolate; /* required on Safari, as `overflow: hidden` does not impose border radius */
  position: relative;
  color: var(--gray11);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-clip: padding-box; /* excludes border from background */
  font-size: 1rem;
}

.dataContainer {
  position: relative;
  display: flex;
  gap: 1.75rem;
  height: fit-content;
  justify-content: space-between;
}

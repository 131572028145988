.wrapper{
    display: flex;
    width: 100%;
    backdrop-filter: blur(4px);
    background: #FFFFFF01;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin-top: 1rem;
}

.content {
    padding: 2px;
    overflow: hidden;
    width: 8rem;
    height: 3.5rem;
    border-radius: 99rem;
    isolation: isolate; /* required on Safari, as `overflow: hidden` does not impose border radius */
    position: relative;
    background: linear-gradient(var(--gray3), var(--gray2));
    border: 1px solid var(--gray7);
    color: var(--gray12);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-clip: padding-box; /* excludes border from background */
    font-size: 1rem;
}

.container {
  height: 400px;
}

.loadingContainer {
  display: flex;
  width: 100%;
  position: absolute;
  backdrop-filter: blur(4px);
  background: #ffffff01;
  height: 400px;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.shinyBoxContainer {
  padding: 2px;
  overflow: hidden;
  width: fit-content;
  height: 3.5rem;
  border-radius: 99rem;
  isolation: isolate; /* required on Safari, as `overflow: hidden` does not impose border radius */
  margin-top: -3rem;
}

.shinyBox {
  position: relative;
  height: 100%;
  width: 100%;
  background: linear-gradient(var(--gray3), var(--gray2));
  color: var(--gray12);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99rem;
  padding: 1rem 1.5rem;
  background-clip: padding-box;
  font-size: 1rem;
}

.shinyBox svg {
  height: 2rem;
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.shinyBox::before {
  content: '';
  position: absolute;
  height: 800%;
  width: 800%;
  background: var(--shine-border);
  z-index: -1;
  animation: rotate 0.5s infinite linear;
}

.errorBox {
  position: relative;
  height: 100%;
  width: 100%;
  background: linear-gradient(var(--gray3), var(--gray2));
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99rem;
  background-clip: padding-box; /* excludes border from background */
  font-size: 1rem;
  color: var(--error11);
  padding: 1rem 1.5rem;
  text-align: center;
  cursor: pointer;
}

.errorBox svg {
  height: 2rem;
  color: var(--error9);
}

.errorBox::before {
  content: '';
  position: absolute;
  height: 800%;
  width: 800%;
  background: var(--error9);
  z-index: -1;
  animation: rotate 0.5s infinite linear;
}

.wrapper {
  display: flex;
  width: 600px;
  backdrop-filter: blur(4px);
  background: #ffffff01;
  height: 187px;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  border-left: 1px solid var(--gray7);
  margin-left: 25px;
  border-bottom: 1px solid var(--gray7);
}

.content {
  padding: 2px;
  overflow: hidden;
  height: 3.5rem;
  border-radius: 99rem;
  isolation: isolate; /* required on Safari, as `overflow: hidden` does not impose border radius */
  position: relative;
  color: #687076;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-clip: padding-box; /* excludes border from background */
  font-size: 1rem;
}

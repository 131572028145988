.trendBadge {
    border-radius: 99rem;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.125rem;
    font-weight: 500;
    color: var(--gray9);
    background: var(--gray3);
}

.smallTrendBadge {
    transform: scale(0.75);
}

.greenBadge {
    color: var(--success11);
    border: solid 1px var(--success9);
    background: var(--success3);
}

.redBadge {
    background: var(--error3);
    color: var(--error11);
    border: solid 1px var(--error9);
}
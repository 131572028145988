.loader,
.loader:after {
    border-radius: 50%;
    width: 3em;
    height: 3em;
}

.loader {
    position: relative;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 0.3em solid rgba(255, 72, 100, 0.63);
    border-right: 0.3em solid rgba(255, 72, 100, 0.63);
    border-bottom: 0.3em solid rgba(255, 72, 100, 0.63);
    border-left: 0.3em solid #e6e6e6;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load 1.1s infinite linear;
    animation: load 1.1s infinite linear;
}

@-webkit-keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind froms;

html,
body,
#root {
  height: 100vh;
}

.rs-btn-primary:focus,
.rs-btn-primary {
  background-color: var(--rs-btn-primary-hover-bg) !important;
}

.rs-picker-daterange-panel .rs-calendar {
  display: block;
  margin: 0 auto;
  min-width: 110px;
}
.rs-picker-daterange-calendar-group {
  min-width: 100px;
  display: flex;
}
.rs-picker-daterange-panel .rs-calendar .rs-calendar-time-dropdown-column > ul {
  height: 125px;
  padding-bottom: 0;
}

.active .foodollar-icon {
  filter: var(--tw-blur) brightness(0) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
    var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
